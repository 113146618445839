import ModalSearchEnum from '../enumerators/modal-search.enum'
import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.openSearchModal()
        NavbarComponent.closeSearchModal()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true && $(NavbarEnum.BODY).hasClass('home')) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true && $(NavbarEnum.BODY).hasClass('home')) {
                    setTimeout(() => {
                        NavbarComponent.changeLogoColor('banner')
                        stateOnTop = true
                    }, 500)
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            //If modal search is open, close it
            $(NavbarEnum.BODY).removeClass('customer-modal-search-open')

            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BODY).removeClass('lang-switcher-active')
                $(NavbarEnum.BODY).removeClass('menu-open')
                $(NavbarEnum.BODY).addClass('menu-close')

                if (
                    $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                    $(NavbarEnum.BODY).hasClass('home')
                ) {
                    setTimeout(() => {
                        NavbarComponent.changeLogoColor('banner')
                    }, 500)
                }
            } else {
                $(NavbarEnum.BODY).addClass('menu-open')
                $(NavbarEnum.BODY).removeClass('menu-close')

                if (
                    $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                    $(NavbarEnum.BODY).hasClass('home')
                ) {
                    NavbarComponent.changeLogoColor('classic')
                }
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        // Close
        $(NavbarEnum.BURGER_BACKGROUND).on('click', () => {
            $(NavbarEnum.BODY).removeClass('lang-switcher-active')
            $(NavbarEnum.BODY).removeClass('menu-open')
            $(NavbarEnum.BODY).addClass('menu-close')
            if (
                $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                $(NavbarEnum.BODY).hasClass('home')
            ) {
                setTimeout(() => {
                    NavbarComponent.changeLogoColor('banner')
                }, 500)
            }
        })

        // Close on escape key
        $(document).on('keyup', (e) => {
            if (e.keyCode === 27) {
                $(NavbarEnum.BODY).removeClass('lang-switcher-active')
                $(NavbarEnum.BODY).removeClass('menu-open')
                $(NavbarEnum.BODY).addClass('menu-close')
                if (
                    $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                    $(NavbarEnum.BODY).hasClass('home')
                ) {
                    setTimeout(() => {
                        NavbarComponent.changeLogoColor('banner')
                    }, 500)
                }
            }
        })
    }

    static openSearchModal() {
        $(ModalSearchEnum.TRIGGER).on('click', () => {
            // If burger menu is open, close it
            if ($(ModalSearchEnum.BODY).hasClass('menu-open')) {
                $(ModalSearchEnum.BODY).removeClass('lang-switcher-active')
                $(ModalSearchEnum.BODY).removeClass('menu-open')
                $(ModalSearchEnum.BODY).addClass('menu-close')
            }

            $(ModalSearchEnum.BODY).toggleClass('customer-modal-search-open')

            if ($(ModalSearchEnum.BODY).hasClass('customer-modal-search-open')) {
                $(ModalSearchEnum.MODAL_SEARCH_INPUT).focus()
                if (
                    $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                    $(NavbarEnum.BODY).hasClass('home')
                ) {
                    NavbarComponent.changeLogoColor('classic')
                }

                //Hide ecomode
                $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
            } else {
                if (
                    $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                    $(NavbarEnum.BODY).hasClass('home')
                ) {
                    setTimeout(() => {
                        NavbarComponent.changeLogoColor('banner')
                    }, 500)
                }
            }
        })
    }

    static closeSearchModal() {
        $(ModalSearchEnum.MODAL_SEARCH_BACKGROUND).on('click', () => {
            $(ModalSearchEnum.BODY).removeClass('customer-modal-search-open')
            if (
                $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                $(NavbarEnum.BODY).hasClass('home')
            ) {
                setTimeout(() => {
                    NavbarComponent.changeLogoColor('banner')
                }, 500)
            }
        })

        //On escape key
        $(document).on('keyup', (e) => {
            if (e.keyCode === 27) {
                $(ModalSearchEnum.BODY).removeClass('customer-modal-search-open')
                if (
                    $(NavbarEnum.NAVBAR).hasClass('navbar--ontop') &&
                    $(NavbarEnum.BODY).hasClass('home')
                ) {
                    setTimeout(() => {
                        NavbarComponent.changeLogoColor('banner')
                    }, 500)
                }
            }
        })
    }
}
