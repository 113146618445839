import FooterEnum from '../enumerators/footer.enum'

export default class FooterComponent {
    constructor() {
        FooterComponent.getGuestsuiteData()
    }

    static getGuestsuiteData() {
        $.ajax({
            url: 'https://wire.guest-suite.com/rest/establishment.json?access_token=MrwvoXaYEL9Wwtwx&establishment_id_guestapp=35996',
            method: 'GET',
        }).done((response) => {
            const rating = response.average_rate ?? 0
            const reviews = response.total_reviews ?? 0

            if (rating > 0 && reviews > 0) {
                $(FooterEnum.GUESTSUITE_RATING).text(rating)
                $(FooterEnum.GUESTSUITE_REVIEWS).text(reviews)
                $(FooterEnum.GUESTSUITE).addClass('active')
            }
        })
    }
}
