import NavbarComponent from '../components/navbar.component'
import BannerEnum from '../enumerators/banner.enum'
import BookingEngineEnum from '../enumerators/booking-engine.enum'
import LangSwitcherEnum from '../enumerators/lang-switcher.enum'

export default class HeaderService {
    constructor() {
        new NavbarComponent()

        // Banner
        if (!IRISCollectionCustomer.isEcomodeActive) {
            if (
                $(BannerEnum.VIDEO).length > 0 &&
                window.matchMedia('(min-width: 1001px)').matches
            ) {
                import(
                    '@scripts/components/banner-video.component' /* webpackChunkName: "scripts/banner-video.component" */
                ).then(({ default: BannerVideoComponent }) => {
                    new BannerVideoComponent()
                })
            } else if ($(BannerEnum.SLIDER).length > 0) {
                import(
                    '@scripts/components/banner-slider.component' /* webpackChunkName: "scripts/banner-slider.component" */
                ).then(({ default: BannerSliderComponent }) => {
                    new BannerSliderComponent()
                })
            }
        }

        if ($(BannerEnum.HEADER_INTRO_MORE).length > 0) {
            import(
                '@scripts/components/banner.component' /* webpackChunkName: "scripts/banner.component" */
            ).then(({ default: BannerComponent }) => {
                new BannerComponent()
            })
        }

        // Booking engine
        if ($(BookingEngineEnum.ENGINE_BOOKING).length > 0) {
            import(
                '@scripts/components/booking-engine.component' /* webpackChunkName: "scripts/booking-engine.component" */
            ).then(({ default: BookingEngineComponent }) => {
                new BookingEngineComponent()
            })
        }

        // Lang switcher
        if ($(LangSwitcherEnum.LANG_SWITCHER).length > 0) {
            import(
                '@scripts/components/lang-switcher.component' /* webpackChunkName: "scripts/lang-switcher.component" */
            ).then(({ default: LangSwitcherComponent }) => {
                new LangSwitcherComponent()
            })
        }
    }
}
