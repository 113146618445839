/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import FooterComponent from './components/footer.component'
import HeaderService from './services/header.service'
import LayoutService from './services/layout.service'
import SingleWpetService from './services/single-wpet.service'

document.addEventListener('DOMContentLoaded', () => {
    new HeaderService()
    new FooterComponent()
    new LayoutService()
    new SingleWpetService()

    // Launch animations
    if (!IRISCollectionCustomer.isEcomodeActive) {
        $('body').addClass('custom-animation-start')
    }

    wp.hooks.addFilter('wp-etourisme.override-lang-os', 'App', (codeLang) => {
        if (typeof gtranslateSettings !== 'undefined') {
            const htmlLang = document.querySelector('html').getAttribute('lang')
            if (htmlLang === 'en') {
                return 'uk'
            }
        }
        return codeLang
    })
})
