export default class BookingEngineEnum {
    static BODY = 'body'
    static ENGINE_BOOKING = '[data-element="engine-booking"]'
    static ENGINE_BOOKING_OPEN = '[data-element="engine-booking-open"]'
    static ENGINE_BOOKING_CLOSE = '[data-element="engine-booking-close"]'
    static ENGINE_BOOKING_FORM = '[data-element="engine-booking-form"]'
    static ENGINE_BOOKING_FORM_MOBILE = '[data-element="engine-booking-form-mobile"]'
    static ENGINE_BOOKING_INPUT = '[data-element="engine-booking-input"]'
    static ENGINE_BOOKING_SUBMIT = '[data-element="engine-booking-submit"]'
}
