import ItineraryButtonsEnum from '../enumerators/itinerary-buttons.enum'
import WidgetGuestSuiteEnum from '../enumerators/widget-guest-suite.enum'

export default class SingleWpetService {
    constructor() {
        if ($('.single-wpet').length > 0) {
            // Widget guest suite
            if ($(WidgetGuestSuiteEnum.GUESTSUITE).length > 0) {
                import(
                    '@scripts/components/detail-page-template/widget-guest-suite.component' /* webpackChunkName: "scripts/widget-guest-suite.component" */
                ).then(({ default: WidgetGuestSuiteComponent }) => {
                    new WidgetGuestSuiteComponent()
                })
            }

            // Widget itinerary button
            if ($(ItineraryButtonsEnum.BUTTON).length > 0) {
                import(
                    '@scripts/components/detail-page-template/itinerary-buttons.component' /* webpackChunkName: "scripts/itinerary-buttons.component" */
                ).then(({ default: ItineraryButtonsComponent }) => {
                    new ItineraryButtonsComponent()
                })
            }

            // Highlights
            import(
                '@scripts/components/detail-page-template/highlights.component' /* webpackChunkName: "scripts/highlights.component" */
            ).then(({ default: HighlightsComponent }) => {
                new HighlightsComponent()
            })
        }
    }
}
